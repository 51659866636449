import React, { useState, useEffect } from 'react'
import useToken from "./useToken";
import { Container, AppBar, Toolbar, Typography, Button, TextField, Select, MenuItem, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import Alert from '@mui/lab/Alert';
import moment from 'moment';
import 'moment/locale/hu'; // Importáljuk a magyar lokalizációt
import CopyToClipboard from 'react-copy-to-clipboard';

moment.updateLocale('hu', {
    week: {
        dow: 1, // Hétfő lesz a hét első napja
    },
});

function Home() {
    const { token, setToken } = useToken();
    const [date, setDate] = useState(moment());
    const [taskCode, setTaskCode] = useState('')
    const [testResult, setTestResult] = useState('Helyes')
    const [timeSpent, setTimeSpent] = useState(0)
    const [showAUTCheckbox, setShowAUTCheckbox] = useState('no')
    const [includeRemaining, setIncludeRemaining] = useState('no')
    const [buttonState, setButtonState] = useState({ text: "Mentés", disabled: false });
    const [logButtonState, setLogButtonState] = useState({ disabled: false });
    let jTask;
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
    const [worklogComment, setWorklogComment] = useState('');
    const [logDate, setLogDate] = useState(moment());
    const [apiResult, setApiResult] = useState([]);

    const options = [
        { value: 'Helyes', label: 'Helyes' },
        { value: 'Hibás', label: 'Hibás' },
        { value: 'Egyeztetés', label: 'Egyeztetés' },
        { value: 'Meeting', label: 'Meeting' },
        { value: 'Folyamatban', label: 'Folyamatban' },
        { value: 'Support', label: 'Support' },
        { value: 'Tech issue', label: 'Tech issue' }
    ];
    const defaultOption = options[0];

    const logOut = () => {
        localStorage.removeItem('token');
        window.location.reload(false);
    }

    function getTaskFromJira(taskCode) {
        const url = 'https://profloggerserver.develanjaro.com/getTask'
        return fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                taskCode: taskCode.taskCode,
                token: token
            })
        })
            .then((response) => {
                if (!response.ok) return { task: 'error' };
                else return response.json();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function clear() {
        setShowAUTCheckbox("no");
        setIncludeRemaining("no");
        setDate(moment());
        setTimeSpent(0);
        setTaskCode('');
        setWorklogComment('')
    }

    function logTime({ taskCode, task }) {
        console.log(date)
        const url = 'https://profloggerserver.develanjaro.com/logTime'
        return fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                taskCode: taskCode.taskCode,
                token: token,
                timeSpentSeconds: timeSpent * 60,
                started: moment(date).format().split('T')[0].concat('T11:11:11.000+0100'),
                testExecuted: moment(date).format('YYYYMMDD'),
                key: task.key,
                type: task.fields.issuetype.name,
                projectKey: task.fields.project.key,
                projectName: task.fields.project.name,
                created: task.fields.created.split('T')[0].replace('-', '').replace('-', ''),
                priority: task.fields.priority.name,
                epicname: task.fields.epicname,
                testResult: testResult,
                labels: '',
                user: JSON.parse(localStorage.getItem('token')).user,
                taskName: task.fields.summary,
                includeRemaining: includeRemaining,
                worklogComment: worklogComment,
            })
        })
            .then((response) => {
                console.log(response);
                if (!response.ok) return { task: 'error' };
                else 
                {
                    setWorklogComment('');
                    return response.json();
                }
            })
            .catch((error) => {
                console.log(error);
                alert('ERROR');
            })
    }

    const handleTaskCodeChange = (e) => {
        const newTaskCode = e.target.value.toUpperCase().trim().split("/").slice(-1);
        const isAUT = newTaskCode[0].includes("AUT");

        setTaskCode(newTaskCode);
        setShowAUTCheckbox(isAUT ? "yes" : "no");
        setIncludeRemaining(isAUT ? "yes" : "no");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (timeSpent === 0) {
            setAlert({ open: true, message: 'Nem lehet a logolt idő 0 perc!', severity: 'error' });
            return;
        }

        if (taskCode === '') {
            setAlert({ open: true, message: 'Nincs kiválasztott task!', severity: 'error' });
            return;
        }
        setButtonState({ text: "Folyamatban...", disabled: true });

        const responseTask = await getTaskFromJira({ taskCode: taskCode });

        if (responseTask.task === 'error') {
            setAlert({ open: true, message: 'Nem sikerült lekérni a taskot, lehet el van írva a task kódja?', severity: 'error' });
            setButtonState({ text: "Mentés", disabled: false });
            return;
        } else {
            jTask = responseTask.task;
            if (jTask.fields.customfield_10006 == null) {
                jTask.fields.epicname = '';
            } else {
                const epicnameTemp = await getTaskFromJira({ taskCode: jTask.fields.customfield_10006 });
                jTask.fields.epicname = epicnameTemp.task.fields.customfield_10005;
            }
        }

        // Logoljuk az időt a taskra
        const responseLogTime = await logTime({
            taskCode,
            task: jTask
        });

        setButtonState({ text: "Mentés", disabled: false });

        if (responseLogTime.task === 'error') {
            setAlert({ open: true, message: 'Nem sikerült a logolás!', severity: 'error' });
        } else {
            setAlert({ open: true, message: 'Sikeres mentés!', severity: 'success' });
            setTimeout(() => setAlert({ ...alert, open: false }), 3000);
            console.log(responseLogTime);
        }
    }

    const handleApiCall = async () => {
        setTimeout(function() {}, 100);
        try {
            setLogButtonState({ disabled: true });
            const response = await fetch('https://profloggerserver.develanjaro.com/getUserWorklogs', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token: token,
                    date: logDate.format('YYYY-MM-DD'),
                    user: JSON.parse(localStorage.getItem("token")).user,
                })
            });

            if (!response.ok) {
                console.error('API call failed');
                setLogButtonState({ disabled: false });
                return;
            }

            const data = await response.json();
            const formattedWorklogs = data.worklogs ? data.worklogs.map((worklog) => ({
                taskCode: worklog.taskCode,
                taskTitle: worklog.taskTitle,
                comments: worklog.comments,
            })) : [];
    
            setApiResult(formattedWorklogs);
            setLogButtonState({ disabled: false });
        } catch (error) {
            console.error('Error during API call', error);
        }
    };

    const handleQuickDate = (offset) => {
        const newDate = moment().subtract(offset, 'days');
        setLogDate(newDate);
        handleApiCall();
    };
    
    const handleCopyToClipboard = async () => {
        try {
            const clipboardText = apiResult.map((log) => `${log.taskCode} - ${log.taskTitle} - ${log.comments}`).join('\n');
            const clipboardHtml = apiResult.map((log) => `<a href="https://jira.profession.hu/browse/${log.taskCode}" target="_blank" rel="noopener noreferrer">${log.taskCode}</a> - ${log.taskTitle} - ${log.comments}`).join('<br>');
    
            const clipboardItems = [
                new ClipboardItem({ "text/plain": new Blob([clipboardText], { type: "text/plain" }) }),
                new ClipboardItem({ "text/html": new Blob([clipboardHtml], { type: "text/html" }) }),
            ];
    
            await navigator.clipboard.write(clipboardItems);
    
            // Esetlegesen írj ki egy üzenetet, hogy a másolás megtörtént
            console.log('Logs copied to clipboard!');
        } catch (error) {
            console.error('Clipboard write failed:', error);
        }
    };
    
    
    
    

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Prof Logger
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
                        {JSON.parse(localStorage.getItem("token")).user}
                    </Typography>
                    <Button color="inherit" onClick={logOut}>
                        Kijelentkezés
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg">
                <Grid container spacing={3} marginTop={2}>
                    <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={clear}>
                            Mindent kiürít
                        </Button>
                        {showAUTCheckbox === "yes" && 
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeRemaining === "yes"}
                                        onChange={(e) => {
                                            const newIncludeRemaining = e.target.checked ? "yes" : "no";
                                            setIncludeRemaining(newIncludeRemaining);
                                        }}
                                    />
                                }
                                label="Vonódjon a remainingből"
                            />
                        }
                    </Grid>
                    <Grid item xs={6} >
                        
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '8px' }}>
                            {[
                                [1, 1.5, 2, 2.5],
                                [3, 3.5, 4, 4.5],
                                [5, 5.5, 6, 6.5],
                                [7, 7.5, 8, 8.5],
                                [9, 9.5, 10, 10.5],
                            ].map((row, rowIndex) => (
                                <div key={rowIndex} style={{ display: 'flex', gap: '4px' }}>
                                    {row.map((hour) => (
                                        <Button
                                            key={hour}
                                            variant="outlined"
                                            onClick={() => setTimeSpent(hour * 60)}
                                            style={{ minWidth: '60px' }}
                                        >
                                            {hour}
                                        </Button>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Task-kód"
                            type="text"
                            id="task"
                            onChange={handleTaskCodeChange}
                            value={taskCode}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Teszt ideje"
                                value={date}
                                onChange={(newDate) => setDate(newDate)}
                                format="YYYY-MM-DD"
                                renderInput={(props) => <TextField {...props} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={testResult}
                            onChange={(event) => {
                                setTestResult(event.target.value);
                            }}
                            fullWidth
                        >
                            {options.map((option) => (
                                <MenuItem value={option.value} key={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Logolandó idő(perc)"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            value={timeSpent}
                            onChange={(event) => {
                                setTimeSpent(parseInt(event.target.value));
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Worklog komment"
                            type="text"
                            value={worklogComment}
                            onChange={(event) => setWorklogComment(event.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" disabled={buttonState.disabled} onClick={handleSubmit} fullWidth>
                            {buttonState.text}
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Dátum"
                                value={logDate}
                                onChange={(newDate) => setLogDate(newDate)}
                                format="YYYY-MM-DD"
                                renderInput={(props) => <TextField {...props} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={logButtonState.disabled} onClick={handleApiCall} fullWidth>
                            Logok lekérése
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled onClick={() => handleQuickDate(0)} fullWidth>
                            Mai
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled onClick={() => handleQuickDate(1)} fullWidth>
                            Tegnapi
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled onClick={() => handleQuickDate(0)} fullWidth>
                            Múlt pénteki
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <CopyToClipboard text="Copy logs to clipboard" onCopy={handleCopyToClipboard}>
                            <Button variant="contained" color="primary" fullWidth>
                                Másolás
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ background: '#f0f8ff', padding: '8px', borderRadius: '8px' }}>
                            {apiResult.map((log, index) => (
                                <div key={index}>
                                    <a href={`https://jira.profession.hu/browse/${log.taskCode}`} target="_blank">
                                        {log.taskCode}
                                    </a> - {log.taskTitle} - {log.comments}
                                </div>
                            ))}
                        </div>
                    </Grid>
                    {alert.open && (
                        <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, open: false })}>
                            {alert.message}
                        </Alert>
                    )}
                </Grid>
            </Container>
        </div>
    );
}


export default Home;